.students-main-container{
  margin: auto;
  max-width: 1140px;
  padding: 20px;
}

.feedback-message{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}