.ant-popover-content{
  width: 350px;
  background: #FFFFFF;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.ant-popover-inner{
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  padding: 0px !important;
}
.ant-popover-title{
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  padding: 12px;
  margin-bottom: 0px !important;
}
.ant-popover-inner-content{
  padding: 12px;
}