.login-main{
  margin: auto;
  max-width: 500px;
  padding: 100px 16px 100px;
  text-align: center;
  .ant-form-item-control-input, .ant-input-affix-wrapper{
    height: 55px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
}

.login-form-button{
  width: 100%;
  height: 60px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.heading{
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.subtitle{
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.login-form-forgot{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #384E48;
  margin-top:36px;
  &:hover{
    color: #7E8F52;
  }
}

.login-form-remember{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #384E48;
  margin-bottom: 30px;
  text-align: end;
  &:hover{
    color: #7E8F52;
  }
}