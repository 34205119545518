.sidebar-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .ant-menu-root{
    border-inline-end:0px !important;
    background: #7E8E51;
    margin: 8px 24px;

    .ant-menu-item{
      height: 50px;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #FFFFFF;
      padding: 16px 20px;
      &-selected{
        background: #384E48;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        font-weight: 400;
      }
      .ant-menu-title-content{
        margin-left: 16px;
      }
    }
  }

}