.dashboard-main-container{
  display: flex;
  margin: auto;
  max-width: 1140px;
  padding: 20px;
  flex-direction: column-reverse;
}

.table-container{
  background-color: white;
  padding: 15px;
  box-shadow: 21px 31px 60px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 3px;
}