.ant-select-selection-item .ant-checkbox {
  display: none;
}
.ant-select-dropdown .ant-select-item-option-state {
  display: none;
}
.ant-select-multiple{
  .ant-select-selector{
    height: 50px;
    overflow-y: auto;
    border: 0.5px solid #7E8E51 !important;
    border-radius: 5px;
  }
}