.students-main-container{
  margin: auto;
  max-width: 1140px;
  padding: 20px;
}
.resource-container{
  padding: 11px;
    height: 40px;
    background: white;
    border-radius: 5px;
    /* color: black; */
    margin-top: 4px;
}