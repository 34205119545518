.students-main-container{
  margin: auto;
  max-width: 1140px;
  padding: 20px;
}

.add-more{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #7E8E51;
  text-align: end;
  cursor: pointer;
}
.add-more-container{
  background: rgb(248 249 249);
  border-radius: 5px;
  padding: 20px 10px 0px;
  margin-left: -10px;
  margin-right: -10px;
  position: relative;
}

.minus-btn{
  position: absolute;
  right: -18px;
  margin-top: 40px;
  cursor: pointer;
}