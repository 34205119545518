.kids-card-container{
  background: #fff;
  border-radius: 9px;
  padding: 6px 16px 6px 6px;
  column-gap: 16px;
  &--subtitle{
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
    color: #424242;
    column-gap: 10px;
    margin-bottom: 4px;
  }
}