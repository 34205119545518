.header-banner{
  position: relative;
  margin-left: -20px;
  margin-top: 10px;
  .ant-image{
    width: 100%;
  }
  &-title{
    position: absolute;
    top: 107px; 
    left: 89px;
    .home-greetings{
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
      color: #000;
    }
    .home-subtitle{
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #000;
    }
  }
}
.header-container{
  display: flex;
  margin: auto;
  max-width: 1140px;
  padding: 0px 20px 0px 20px;
  margin-top: -40px;
  align-items: center;
  .table-search {
    float: right;
    &-input {
      padding: 0px 7px !important;
      height: 48px;
      max-width: 810px;
      border-color: #fff !important;
      box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.05);
      .ant-input {
        font-size: 12px;
        line-height: 18px;
        border-radius: 0;
      }
    }
  }
}