.details-link{
  background: #ffffff80;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 18px 12px;
  .ant-anchor-link-title{
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) -19.14%, rgba(0, 0, 0, 0.752) 79.78%);
  border-radius: 5px 5px 0px 0px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.resource-description{
  background: #7E8E51;
  box-shadow: 0px 5px 20px rgb(0 0 0 / 10%);
  border-radius: 0px 0px 5px 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  padding: 16px 12px;
  margin-bottom: 16px;
}