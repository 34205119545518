.counter-main-container{
  height: 140px;
  background: white;
  box-shadow: 19.2639px 28.4372px 55.0398px rgba(0, 0, 0, 0.05);
  border-radius: 9.17329px;
  margin-bottom: 6px;
  width: 259px;
  max-width: 300px;
  cursor: pointer;
}

.couner-sub-section{
  display: flex;
  width: 100%;
  height: 100%;
  background: #384E48;
  box-shadow: 19.4244px 28.6741px 55.4983px rgba(0, 0, 0, 0.05);
  border-radius: 9.24972px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: white;
  padding: 16px;
}

.counter-icon{
  border-radius: 50%;
  background: #FFFFFF;
  // height: 70px;
  // width: 70px;
  padding: 24px;
}

.counter-details{
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6px;
  &-count{
    font-weight: 700;
    font-size: 46.2486px;
    line-height: 53px;
    color: #FFFFFF;
  }
  &-label{
    font-weight: 500;
    font-size: 12.9496px;
    line-height: 15px;
    color: #FFFFFF;
  }
}